/* Set a height for map */
#map {
  height: 500px;
}

/* Google maps card styles - COPIED */
.gm-style .place-card div,
.gm-style .place-card a,
.gm-style .default-card div,
.gm-style .default-card a {
  color: #5B5B5B;
  font-family: Roboto, Arial;
  font-size: 12px;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text
}

.gm-style .place-card,
.gm-style .default-card,
.gm-style .directions-card {
  cursor: default
}

.gm-style .place-card-large {
  padding: 9px 4px 9px 11px
}

.gm-style .place-card-medium {
  width: auto;
  padding: 9px 11px 9px 11px
}

.gm-style .default-card {
  padding: 5px 14px 5px 14px
}

.gm-style .place-card a:link,
.gm-style .default-card a:link,
.gm-style .directions-card a:link {
  text-decoration: none;
  color: #3a84df
}

.gm-style .place-card a:visited,
.gm-style .default-card a:visited,
.gm-style .directions-card a:visited {
  color: #3a84df
}

.gm-style .place-card a:hover,
.gm-style .default-card a:hover,
.gm-style .directions-card a:hover {
  text-decoration: underline
}

.gm-style .place-desc-large {
  width: 200px;
  display: inline-block
}

.gm-style .place-desc-medium {
  display: inline-block
}

.gm-style .place-card .place-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 14px;
  color: black
}

html[dir="rtl"] .gm-style .place-name {
  padding-right: 5px
}

.gm-style .place-card .address {
  margin-top: 6px
}

.gm-style .tooltip-anchor {
  width: 100%;
  position: relative;
  float: right;
  z-index: 1
}

.gm-style .star-entity .tooltip-anchor,
.gm-style .star-entity-medium .tooltip-anchor,
.gm-style .navigate-entity-medium .tooltip-anchor,
.gm-style .navigate .tooltip-anchor {
  width: 50%;
  display: none
}

.gm-style .star-entity:hover .tooltip-anchor,
.gm-style .star-entity-medium:hover .tooltip-anchor,
.gm-style .navigate-entity-medium:hover .tooltip-anchor,
.gm-style .navigate:hover .tooltip-anchor {
  display: inline
}

.gm-style .tooltip-anchor>.tooltip-tip-inner,
.gm-style .tooltip-anchor>.tooltip-tip-outer {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  background-color: transparent;
  position: absolute;
  left: -8px
}

.gm-style .tooltip-anchor>.tooltip-tip-outer {
  border-bottom: 8px solid #CBCBCB
}

.gm-style .tooltip-anchor>.tooltip-tip-inner {
  border-bottom: 8px solid white;
  z-index: 1;
  top: 1px
}

.gm-style .tooltip-content {
  position: absolute;
  top: 8px;
  left: -70px;
  line-height: 137%;
  padding: 10px 12px 10px 13px;
  width: 210px;
  margin: 0;
  border: 1px solid #CBCBCB;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: white
}

html[dir="rtl"] .gm-style .tooltip-content {
  left: -10px
}

.gm-style .star-entity-medium .tooltip-content {
  width: 110px
}

.gm-style .navigate {
  display: inline-block;
  vertical-align: top;
  height: 43px;
  padding: 0 7px
}

.gm-style .navigate-entity-medium {
  display: inline-block;
  vertical-align: top;
  width: 17px;
  height: 17px;
  margin-top: 1px
}

.gm-style .navigate-link {
  display: block
}

.gm-style .place-card .navigate-text,
.gm-style .place-card .star-entity-text {
  margin-top: 5px;
  text-align: center;
  color: #3a84df;
  font-size: 12px;
  max-width: 55px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

.gm-style .place-card .hidden {
  margin: 0;
  padding: 0;
  height: 0;
  overflow: hidden
}

.gm-style .navigate-icon {
  width: 22px;
  height: 22px;
  overflow: hidden;
  margin: 0 auto
}

.gm-style .navigate-icon {
  border: 0
}

.gm-style .navigate-separator {
  display: inline-block;
  width: 1px;
  height: 43px;
  vertical-align: top;
  background: -webkit-linear-gradient(top, #fbfbfb, #e2e2e2, #fbfbfb);
  background: -moz-linear-gradient(top, #fbfbfb, #e2e2e2, #fbfbfb);
  background: -ms-linear-gradient(top, #fbfbfb, #e2e2e2, #fbfbfb);
  background: -linear-gradient(top, #fbfbfb, #e2e2e2, #fbfbfb)
}

.gm-style .star-entity {
  display: inline-block;
  vertical-align: top;
  height: 43px;
  padding: 0 7px
}

.gm-style .star-entity .star-button {
  cursor: pointer
}

.gm-style .navigate-entity .navigate-button {
  cursor: pointer
}

.gm-style .star-entity-medium {
  display: inline-block;
  vertical-align: top;
  width: 17px;
  height: 17px;
  margin-top: 1px
}

.gm-style .star-entity:hover .star-entity-text {
  text-decoration: underline
}

.gm-style .star-entity-icon-large {
  width: 22px;
  height: 22px;
  overflow: hidden;
  margin: 0 auto
}

.gm-style .star-entity-icon-medium {
  width: 17px;
  height: 17px;
  top: 0px;
  overflow: hidden;
  margin: 0 auto
}

.gm-style .navigate-entity-icon-medium {
  width: 17px;
  height: 17px;
  top: 0px;
  overflow: hidden;
  margin: 0 auto
}

.gm-style .can-star-large {
  position: relative;
  cursor: pointer;
  width: 22px;
  height: 22px;
  overflow: hidden;
  margin: 0 auto
}

.gm-style .logged-out-star,
.logged-out-star:hover {
  position: relative;
  cursor: pointer;
  width: 22px;
  height: 22px;
  overflow: hidden;
  margin: 0 auto
}

.gm-style .is-starred-large {
  position: relative;
  cursor: pointer;
  width: 22px;
  height: 22px;
  overflow: hidden;
  margin: 0 auto
}

.gm-style .can-star-medium {
  position: relative;
  height: 17px;
  top: -2px;
  cursor: pointer
}

.gm-style .is-starred-medium {
  position: relative;
  height: 17px;
  top: -2px;
  cursor: pointer
}

.gm-style .navigate-icon-medium {
  position: relative;
  height: 17px;
  top: -2px;
  cursor: pointer
}

.gm-style .review-box {
  padding-top: 5px
}

.gm-style .place-card .review-box-link {
  padding-left: 8px
}

.gm-style .place-card .review-number {
  display: inline-block;
  color: #e7711b;
  font-weight: 500;
  font-size: 14px
}

.gm-style .rating-star {
  display: inline-block;
  width: 11px;
  height: 11px;
  overflow: hidden
}

.gm-style .directions-card {
  color: #5B5B5B;
  font-family: Roboto, Arial;
  background-color: white;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text
}

.gm-style .directions-card-medium-large {
  height: 71px;
  padding: 10px 11px
}

.gm-style .directions-info {
  padding-left: 25px
}

.gm-style .directions-waypoint {
  height: 20px
}

.gm-style .directions-address {
  font-weight: 400;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black
}

.gm-style .directions-icon {
  float: left;
  vertical-align: top;
  padding-top: 2px;
  height: 50px;
  width: 20px
}

.gm-style .directions-icon div {
  width: 15px;
  height: 45px;
  overflow: hidden
}

.gm-style .directions-separator {
  position: relative;
  height: 1px;
  margin-top: 3px;
  margin-bottom: 4px;
  background-color: #ccc
}

.gm-style .maps-links-box-exp {
  padding-top: 5px
}

.gm-style .time-to-location-info-exp {
  padding-right: 10px;
  border-right: 1px solid #ccc;
  margin-right: 10px;
  display: inline-block
}

.gm-style .google-maps-link-exp {
  display: inline-block;
  vertical-align: middle
}

.gm-style .time-to-location-text-exp {
  vertical-align: middle
}

.gm-style .place-card-large .only-visible-to-you-exp {
  padding-top: 5px;
  color: #ccc;
  display: inline-block
}

.gm-style .place-card-large .time-to-location-privacy-exp .learn-more-exp {
  color: #ccc;
  text-decoration: underline
}

.gm-style .navigate-icon {
  background-position: 0px 0px
}

.gm-style .navigate:hover .navigate-icon {
  background-position: 48px 0px
}

.gm-style .navigate-icon-medium {
  background-position: 0px 94px
}

.gm-style .navigate-icon-medium:hover {
  background-position: -16px 94px
}

.gm-style .can-star-large {
  background-position: 70px 187px
}

.gm-style .star-button:hover .can-star-large {
  background-position: 48px 187px
}

.gm-style .logged-out-star {
  background-position: 96px 187px
}

.gm-style .star-button:hover .logged-out-star {
  background-position: 96px 187px
}

.gm-style .is-starred-large {
  background-position: 0px 166px
}

.gm-style .rating-full-star {
  background-position: 48px 165px
}

.gm-style .rating-half-star {
  background-position: 35px 165px
}

html[dir="rtl"] .gm-style .rating-half-star {
  background-position: 10px 165px
}

.gm-style .rating-empty-star {
  background-position: 23px 165px
}

.gm-style .directions-icon {
  background-position: 0px 144px
}

.gm-style .hovercard-personal-icon-home {
  background-position: 96px 102px
}

.gm-style .hovercard-personal-icon-work {
  background-position: 96px 79px
}

.gm-style .can-star-medium {
  background-position: 0px 36px
}

.gm-style .can-star-medium:hover {
  background-position: -17px 36px
}

.gm-style .logged-out-star-medium {
  background-position: 36px 36px
}

.gm-style .star-button:hover .logged-out-star-medium {
  background-position: 36px 36px
}

.gm-style .is-starred-medium {
  background-position: 0px 19px
}

.gm-style .info {
  height: 30px;
  width: 30px;
  background-position: 19px 36px
}

.saved-from-source-link {
  margin-top: 5px;
  max-width: 331px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.gm-style .drive-icon-exp {
  background-position: -90px -62px;
  display: inline-block;
  width: 18px;
  height: 16px;
  overflow: hidden;
  margin: 0 auto;
  margin-right: 5px;
  vertical-align: middle
}

.gm-style .star-entity-icon-large-exp {
  width: 42px;
  height: 42px;
  overflow: hidden;
  margin: 0 auto
}

.gm-style .can-star-large-exp {
  position: relative;
  cursor: pointer;
  width: 42px;
  height: 42px;
  overflow: hidden;
  margin: 0 auto
}

.gm-style .logged-out-star-exp,
.logged-out-star-exp:hover {
  position: relative;
  cursor: pointer;
  width: 42px;
  height: 42px;
  overflow: hidden;
  margin: 0 auto
}

.gm-style .is-starred-large-exp {
  position: relative;
  cursor: pointer;
  width: 42px;
  height: 42px;
  overflow: hidden;
  margin: 0 auto
}

.gm-style .can-star-large-exp {
  background-position: -46px -46px
}

.gm-style .star-button-exp:hover .can-star-large-exp {
  background-position: -2px -46px
}

.gm-style .logged-out-star-exp {
  background-position: -46px -91px
}

.gm-style .star-button-exp:hover .logged-out-star-exp {
  background-position: -2px -46px
}

.gm-style .is-starred-large-exp {
  background-position: -2px -91px
}

.gm-style .star-button-exp {
  position: absolute;
  right: -10px
}

.gm-style .star-entity-exp .star-button-exp {
  cursor: pointer
}

.gm-style .navigate-icon-exp {
  width: 42px;
  height: 42px;
  overflow: hidden;
  margin: 0 auto
}

.gm-style .navigate-icon-exp {
  border: 0
}

.gm-style .navigate-icon-exp {
  background-position: -46px -1px
}

.gm-style .navigate:hover .navigate-icon-exp {
  background-position: -2px -1px
}

.gm-style .navigate-exp {
  display: inline-block;
  vertical-align: top;
  height: 43px;
  padding: 0 7px;
  position: absolute;
  right: -18px
}

.gm-style .icon {
  background-image: url(https://maps.gstatic.com/mapfiles/embed/images/entity11.png);
  background-size: 70px 210px;
}