.d-flex {
    display: flex;
}
.margin-t-b-10 {
    margin: 20px 0px !important;
}
.margin-auto {
    margin: auto;
}
.margin-0-padding-0 {
    margin: 0px !important;
    padding: 0px !important;
}
.letter-spacing-12 {
    letter-spacing: 5px;
}
.margin-l-20 {
    margin-left: 20px !important;
}
#navbar-brand-img {
    width: 140px;
}
.container-fluid {
    padding: 0px !important;
}
.navbar-link {
    margin-left: auto;
}
.image {
    width: 100%;
    transform: translatez(0);
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    height: 100vh;
}
.second-image {
    width: 100%;
    position: relative;
    transform: translatez(0);
    min-height: 100vh;
}
.main-background-video {
    /* position: relative; */
    min-height: 100vh;
    width: 100%;
    z-index: 0;
}
.nav-cll {
    position: absolute;
    width: 100%;
    background: rgb(0, 0, 0, 0.2);
    z-index: 1;
    padding: 25px;
}
.navbar-light .navbar-nav .nav-link  {
    color: white !important;
    font-weight: bold !important;
    margin: 0px 0px 0px 35px;
    font-size: 14px;
    transition: color 300ms ease-out 50ms
}
.navbar-light .navbar-nav .nav-link:hover  {
    color: orange !important;
}
.sectionOne {
    min-height: 100vh;
    position: relative;
    z-index: 10;
    background-color: white;
}
.sec-one-div {
    font-size: 17px;
    font-family: unset;
}
.sectionOne-container {
    padding: 70px;
    height: 100%;
}
footer {
    background-color: #efeff3;
}
.footer-container {
    padding: 25px 100px !important;
}
.social-icon {
    letter-spacing: 1.6px;
    color: black;
    display: flex;
}
.social-icon span {
    background-repeat: no-repeat;
    width: 25px;
    display: inline-block;
    height: 30px;
}
.social-text {
    margin: 0px;
    margin-left: 5px;
    font-size: 15px;
    font-family: 'Kalam';
}
.align-centre a:hover {
    color: orange;
    transition: color 50ms ease-out 20ms
}
.align-centre {
    justify-content: center;
    display: grid;
}
.padding-20 {
    padding: 20px;
}
.display-block {
    display: block;
}
.made-with {
    text-align: center;
    padding: 20px;
    border-top: 1px solid black;
}
/* footer a {
    display: block;
    height: 3.75rem;
    width: 5.0rem;
} */
.sec-one-div img {
    margin: auto;
    width: 35%;
    text-align: center;
    display: block;
}
.sec-one-div p {
    text-align: center;
    font-family: inherit;
}
.footer-text {
    text-align: center !important;
    font-size: 17px !important;
    font-family: 'Kalam';
}
.slick-slider button {
    height: 100vh;
}
.slick-slider .slick-prev {
    padding-left: 100px;
    z-index: 1;
}
.slick-slider .slick-next {
    padding-right: 135px;
    z-index: 1;
    right: 0;
}
.slick-slider img {
    width: 100%;
}
.slick-prev:before, .slick-next:before {
    font-size: 35px !important;
}
.recent-photograph {
    margin: 75px auto;
    color: #5a5a5a;
    font-weight: bolder;
    font-size: 50px;
    font-family: 'futura';
}
.react-parallax-content {
    align-items: center;
    font-size: 80px;
    margin-left: 30px;
    color: white;
    font-weight: 700;
    font-family: inherit;
    display: flex;
}
.photograph-image-show {
    margin: 100px;
}
.photograph-image-show > img {
    max-width: 100%;
    margin: 20px 0px;
}
.dual-video-container {
    display: flex;
}
.dual-video-container > iframe {
    width: 100%;
}
.video-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}
.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.text-tile {
    background-color: black;
    height: 100%;
    display: flex;
}
.text-tile-heading {
    text-align: center;
    padding: 10px 50px;
    color: blanchedalmond;
}
.text-tile-sub-heading {
    text-align: center;
    color: blanchedalmond;
}
.contact-number { 
    text-align: right;
    font-family: 'Kalam'; 
    font-size: 18px;
}
.contact-us-banner {
    background: url('../asset/Image/resize1.jpg');
    font-family: 'Kaushan Script';
    font-size: 60px;
    padding: 60px 0px !important;
    background-position: center;
}
.contact-us-banner > div {
    margin: auto;
    text-align: center;
    justify-content: center;
    color: white;
}
.react-photo-gallery--gallery {
    margin: 0px 70px 70px 70px!important;
}
.photography-tab-quote {
    margin: 55px auto;
    color: orange;
    font-size: 40px;
    font-family: 'Kaushan Script';
    text-align: center;
}
.text-img {
    height: 100%;
    margin: auto 0px;
    padding: 0px 10px;
}
.padding-l-r-t-b-70 {
    padding: 0px 70px 70px 70px;
}
.email-form-submit {
    display: flex;
    max-width: 300px;
    margin: auto;
}
.btn-primary:hover {
    background-color: #FF8C00;
    border-color: #FF8C00;
    transition: 500ms;
}
.btn-primary {
    transition: 500ms;
    color: #fff;
    background-color: orange;
    border-color: orange;
    height: 38px !important;
}
.map-col > div:nth-child(2){
    max-height: 350px;
}
.contact-form-title {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
}
.react-parallax-bgimage {
    left: 50% !important;
}
.hero-image-photography > img {
    top: -40%;
}
.fade-appear {
    opacity: 0;
    z-index: 1;
    transition: opacity 500ms ease-in;
}
  
.fade-appear.fade-appear-active {
    opacity: 1;
}
.css-1cm5myo {
    max-height: 100vh !important;
}
.hero-image-contactus img {
    top: -17%
}
@media only screen and (max-width: 967px) {
    .contact-us-banner > div {
        font-size: 48px;
    }
}
@media only screen and (max-width: 767px) {
    .padding-l-r-t-b-70 {
        /* padding: 0px 20px 20px 20px; */
        padding: 0px;
    }
    .margin-b-767-40 {
        margin-bottom: 40px;
    }
    .contact-us-banner > div {
        font-size: 36px;
    }
    .hero-image .react-parallax-bgimage {
        left: 0% !important;
     }
    .media-padding-top-100 {
        padding: 100px 50px;
    }
    .sec-one-div img {
        width: 65%;
    }
    .hero-image .react-parallax-bgimage {
        height: auto !important;
    }
    .sectionOne-container {
        padding: 20px;
    }
    .recent-photograph {
        font-size: 40px;
    }
    #navbar-brand-img {
        width: 90px;
    }
    .nav-cll {
        padding: 15px;
    }
    .slick-slider .slick-next {
        padding-right: 60px;
    }
    .slick-prev:before, .slick-next:before {
        font-size: 25px !important;
    }
    .slick-slider .slick-prev {
        padding-left: 60px;
    }
    .footer-container {
        padding: 25px !important;
    }
    .footer-container > div > div {
        text-align: center !important;
        padding-bottom: 20px;
    }
    .contact-number {
        text-align: center;
    }
    .react-parallax-content {
        font-size: 50px;
    }
}
@media only screen and (max-width: 576px) {
    .photography-tab-quote {
        font-size: 32px;
    }
    .hero-image .react-parallax-bgimage {
        left: -22% !important;
    }
    .contact-us-banner > div {
        font-size: 25px;
    }
    .sectionOne-container {
        padding: 10px;
    }
    .video-container {
      margin: 10px 0px;
    }
    .react-photo-gallery--gallery {
        margin: 0px 10px 10px 10px!important;
    }
    .recent-photograph {
        margin: 40px auto;
        font-size: 30px;
    }
    #navbar-brand-img {
        width: 75px;
    }
    .react-parallax-content {
        font-size: 40px;
    }
}
@media only screen and (max-width: 400px) {
    .photography-tab-quote {
        font-size: 22px;
    }
    .hero-image .react-parallax-bgimage {
       left: -35% !important;
    }
    .recent-photograph {
        font-size: 20px;
    }
    .media-padding-top-100 {
        padding: 100px 0px;
    }
    .react-parallax-content {
        font-size: 30px;
    }
}